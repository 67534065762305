import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/investments"

import investments from "data/investments"

const Apartments = ({ location }) => {
  const title = "Kielce"

  return (
    <Layout
      location={location}
      seo={{
        title: "Kielce - Nowe mieszkania na sprzedaż",
        description:
          "Mieszkania na sprzedaż bezpośrednio od sprawdzonego dewelopera. Najlepsze lokalizacje, przemyślane projekty i zawsze dużo zieleni.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Flats", url: "/en/flats" }}
      />
      <PageHeader title={title} />
      {investments
        ?.filter(item => item.city === "Kielce")
        .map((item, index) => (
          <Content
            key={index}
            city={item?.city}
            sub_title="Projects on sale"
            investments={item?.investments}
            office={item?.office}
            rmSubHeader
          />
        ))}
      {/* <Popup type="kielce" lang="en" /> */}
    </Layout>
  )
}

export default Apartments
